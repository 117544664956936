import React, {useEffect, useState } from 'react';
import './App.css';
import LandingPage from './components/LandingPage';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import the Navbar component
import InputForm from './components/InputForm';
import StoryDisplay from './components/StoryDisplay';
import PaymentModal from './components/PaymentModal';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfService from './components/TermsOfService'
import Error from './components/Error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { initGA4, logPageView } from './analytics';
import ErrorBoundary from './ErrorBoundary';
import { WebSocketProvider } from './contexts/WebSocketContext';
function App() {
  useEffect(() => {
    initGA4();  // Initialize GA when app loads
  }, []);
  const [isFooterVisible, setFooterVisible] = useState(false);

  const toggleFooter = () => {
    setFooterVisible(!isFooterVisible);
  };

	  const RouteChangeTracker = () => {
    const location = useLocation();
    useEffect(() => {
      logPageView();  // Track page view on route change
    }, [location]);

    return null;
  };

  return (
	      <WebSocketProvider>
    <Router>
      <RouteChangeTracker />  {/* Tracks route changes */}
      <div className="App">
	<Navbar /> {/* Navbar at the top */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/create" element={<InputForm />} />
          <Route path="/story" element={<StoryDisplay />} />
	  <Route path="/about" element={<AboutUs />} />
	  <Route path="/contact" element={<ContactUs />} />
	  <Route path="/story/:slug" element={<ErrorBoundary><StoryDisplay /></ErrorBoundary>} />
	  <Route path="/privacy" element={<PrivacyPolicy />} />
	  <Route path="/terms" element={<TermsOfService />} />
	  <Route path="/oops" element={<Error />} />
	  <Route path="*" element={<Error />} />
        </Routes>
        {/* Sticky footer toggle button */}
        <div className="footer-button" onClick={toggleFooter}>
	  <FontAwesomeIcon icon={isFooterVisible ? faChevronUp : faChevronDown} />
        </div>
        <footer className={`footer ${isFooterVisible ? 'footer-visible' : ''}`}>
          <div className="footer-content">
            <nav className="footer-nav">
              <a className="footer-link" href="/about">About Us</a>
              <a className="footer-link" href="/contact">Contact Us</a>
              <a className="footer-link" href="/privacy">Privacy Policy</a>
              <a className="footer-link" href="/terms">Terms of Service</a>
            </nav>
            <p>© 2024 WonderYarn. All Rights Reserved.</p>
          </div>
        </footer>

      </div>
    </Router>
	      </WebSocketProvider>
  );
}

export default App;

