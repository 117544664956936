import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';

const WebSocketContext = createContext();
export const WebSocketProvider = ({ children }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState('Connecting...');
  const [isLoading, setIsLoading] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [connectionError, setConnectionError] = useState(null);
const [currentSlug, setCurrentSlug] = useState(null);
const currentSlugRef = useRef(null);
  let ws;
	  const wsRef = useRef(null);
	  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 3;
  let reconnectTimeout = null;
const updateSlug = (newSlug) => {
  setCurrentSlug(newSlug);
  currentSlugRef.current = newSlug;
  console.log(`Slug updated to: ${newSlug}`);
};

  const connectWebSocket = useCallback((slug, tempId) => {
    if (wsRef.current && (wsRef.current.readyState === WebSocket.OPEN || wsRef.current.readyState === WebSocket.CONNECTING)) {
      console.log('WebSocket is already open or connecting. Updating subscription.');
      wsRef.current.send(JSON.stringify({ type: 'subscribe', 'slug': currentSlugRef.current }));
      return;
    }
	    const connectionSlug = slug || tempId;
  setCurrentSlug(connectionSlug);
  currentSlugRef.current = connectionSlug;  // Sync the ref


    console.log('Attempting to connect WebSocket...');
    //setIsLoading(false);
    setConnectionError(null);
	// dev
    // wsRef.current = new WebSocket('ws://o.wonderyarn.com/ws');
	wsRef.current = new WebSocket('wss://wonderyarn.com/ws');
    wsRef.current.onopen = () => {
      console.log('WebSocket connected');
      setWsConnected(true);
      setLoadingStatus('Connected. Waiting for story processing...');
      reconnectAttemptsRef.current = 0;
      wsRef.current.send(JSON.stringify({ type: 'subscribe', currentSlug, tempId }));
    };
    wsRef.current.onmessage = (event) => {
      console.log('📩 Message from server:', event.data);
      try {
	  const placeSlug = currentSlugRef.current;
        const data = JSON.parse(event.data);
	      console.log(`Progress: ${data.progress}`)
	      if (data.progress == 100) {
		      console.log(`Data: ${data.slug} Slug: ${currentSlug} tempid ${tempId}`)
		      console.warn('data type:', data.type);
	      }
	      if (data.type === 'update_connection') {
		      updateSlug(data.data.newSlug);
		        //const newSlug = data.data.newSlug;
    //console.log(`New slug (raw): ${data.data.newSlug}`);
//		        console.log(`Using the new slug immediately: ${newSlug}`);

    // Log the exact newSlug before updating it

    // Ensure that the currentSlug is updated properly
 //   setCurrentSlug(newSlug);
//currentSlugRef.current = newSlug;  // Keep the ref in sync
    // After setting the new slug, log it again
    console.log(`After update: currentSlug: ${currentSlug}`);
        // Update the WebSocket connection with the new slug
       // connectWebSocket(data.data.newSlug);

      } else if (data && typeof data === 'object') {
	      if (data.slug === placeSlug || data.slug === tempId) {
          if (data.type === 'story_processing' || data.type === 'image_processing') {
            const progress = Math.min(Math.max(parseInt(data.progress) || 0, 0), 100);
            console.log(`Progress update: ${progress}%`);
            setLoadingProgress(progress);
            setLoadingStatus(`Processing: ${data.progress}% complete`);
            if (data.progress === 100) {
              setIsLoading(false);
            }
          } else {
		  console.warn('Unexpected data type:', data.type);
		  console.log(`Data: ${data.slug} Slug: ${placeSlug} tempid ${tempId}`)
        } 
	} else {
        console.warn(`Data: ${data.slug} Slug: ${placeSlug} tempid ${tempId}`);
      }
      }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
      
    };

    wsRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setConnectionError('Unable to connect to the server. Please check your internet connection and try again.');
      setIsLoading(false);
	      if (reconnectAttemptsRef.current < maxReconnectAttempts) {
    reconnectAttemptsRef.current++;
    setTimeout(() => connectWebSocket(slug, tempId), 1000 * reconnectAttemptsRef.current);
  }
    };

    wsRef.current.onclose = () => {
      console.log('WebSocket closed');
      setWsConnected(false);
if (reconnectAttemptsRef.current < maxReconnectAttempts) {
  reconnectAttemptsRef.current++;
  setTimeout(() => connectWebSocket(slug, tempId), 1000 * reconnectAttemptsRef.current);
      } else {
        setConnectionError('Unable to maintain connection. Falling back to regular updates.');
      }
    };
  }, []);
  const disconnectWebSocket = useCallback(() => {
    if (wsRef.current) {
      ws.current.close();
      ws.current = null;
    }
    setWsConnected(false);
    setLoadingStatus('Idle');
    setIsLoading(false);
    setConnectionError(null);
  }, []);

  useEffect(() => {
    return () => {
      disconnectWebSocket();
    };
  }, [disconnectWebSocket]);
useEffect(() => {
  return () => {
    if (wsRef.current) {
      wsRef.current.close();
    }
  };
}, []);
	useEffect(() => {
  currentSlugRef.current = currentSlug;
}, [currentSlug]);
	return (
    <WebSocketContext.Provider value={{
      loadingProgress,
      loadingStatus,
      isLoading,
      setIsLoading,
      wsConnected,
      connectWebSocket,
      disconnectWebSocket,
      connectionError,
	currentSlug
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
