import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import './ChildStoryForm.css' // Add necessary styling here
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading' // Import the loading component
import i18n from 'i18next'; // Import the i18n instance
import { v4 as uuidv4 } from 'uuid';  // Make sure to install and import uuid
import { useTranslation } from 'react-i18next';
import boyIcon from '../assets/icons/boy.svg';
import girlIcon from '../assets/icons/girl.svg';
import neutralIcon from '../assets/icons/neutral.svg'; // Import SVG icons
import { useWebSocket } from '../contexts/WebSocketContext'; // Import WebSocket context
const interestOptions = [
  { value: 'random', label: 'Random' },
  { value: 'airplanes', label: 'Airplanes' },
  { value: 'aliens', label: 'Aliens' },
  { value: 'ambulances', label: 'Ambulances' },
  { value: 'animals_farm', label: 'Farm Animals' },
  { value: 'animals', label: 'Animals' },
  { value: 'animals_safari', label: 'Safari Animals' },
  { value: 'astronomy', label: 'Astronomy' },
  { value: 'baking', label: 'Baking' },
  { value: 'baseball', label: 'Baseball' },
  { value: 'basketball', label: 'Basketball' },
  { value: 'bees', label: 'Bees' },
  { value: 'biking', label: 'Biking' },
  { value: 'birds', label: 'Birds' },
  { value: 'board_games', label: 'Board Games' },
  { value: 'building', label: 'Building' },
  { value: 'butterflies', label: 'Butterflies' },
  { value: 'camping', label: 'Camping' },
  { value: 'card_games', label: 'Card Games' },
  { value: 'cars', label: 'Cars' },
  { value: 'castles', label: 'Castles' },
  { value: 'cats', label: 'Cats' },
  { value: 'chemistry', label: 'Chemistry' },
  { value: 'chess', label: 'Chess' },
  { value: 'circus', label: 'Circus' },
  { value: 'clay_modeling', label: 'Clay Modeling' },
  { value: 'coding', label: 'Coding' },
  { value: 'construction_vehicles', label: 'Construction Vehicles' },
  { value: 'cooking', label: 'Cooking' },
  { value: 'crafts', label: 'Crafts' },
  { value: 'dancing', label: 'Dancing' },
  { value: 'dinosaurs', label: 'Dinosaurs' },
  { value: 'dogs', label: 'Dogs' },
  { value: 'dragons', label: 'Dragons' },
  { value: 'drawing', label: 'Drawing' },
  { value: 'explorers', label: 'Explorers' },
  { value: 'fairies', label: 'Fairies' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'firetrucks', label: 'Firetrucks' },
  { value: 'fishing', label: 'Fishing' },
  { value: 'frogs', label: 'Frogs' },
  { value: 'gardening', label: 'Gardening' },
  { value: 'geology', label: 'Geology' },
  { value: 'hiking', label: 'Hiking' },
  { value: 'history', label: 'History' },
  { value: 'horses', label: 'Horses' },
  { value: 'insects', label: 'Insects' },
  { value: 'knights', label: 'Knights' },
  { value: 'knitting', label: 'Knitting' },
  { value: 'lego', label: 'Lego' },
  { value: 'magic', label: 'Magic' },
  { value: 'magic_spells', label: 'Magic & Spells' },
  { value: 'makeup', label: 'Makeup' },
  { value: 'medieval', label: 'Medieval' },
  { value: 'mermaids', label: 'Mermaids' },
  { value: 'monsters', label: 'Monsters' },
  { value: 'motorcycles', label: 'Motorcycles' },
  { value: 'music', label: 'Music' },
  { value: 'mysteries', label: 'Mysteries' },
  { value: 'mythology', label: 'Mythology' },
  { value: 'ninja', label: 'Ninja' },
  { value: 'origami', label: 'Origami' },
  { value: 'outer_space', label: 'Outer Space' },
  { value: 'painting', label: 'Painting' },
  { value: 'pets', label: 'Pets' },
  { value: 'pirates', label: 'Pirates' },
  { value: 'pirates_treasure', label: 'Pirates & Treasure' },
  { value: 'police_cars', label: 'Police Cars' },
  { value: 'princesses', label: 'Princesses' },
  { value: 'puppets', label: 'Puppets' },
  { value: 'puzzles', label: 'Puzzles' },
  { value: 'reptiles', label: 'Reptiles' },
  { value: 'robots_ai', label: 'Robots & AI' },
  { value: 'robots', label: 'Robots' },
  { value: 'samurai', label: 'Samurai' },
  { value: 'science_experiments', label: 'Science Experiments' },
  { value: 'secret_agents', label: 'Secret Agents' },
  { value: 'sewing', label: 'Sewing' },
  { value: 'singing', label: 'Singing' },
  { value: 'skateboarding', label: 'Skateboarding' },
  { value: 'soccer', label: 'Soccer' },
  { value: 'space', label: 'Space' },
  { value: 'space_ships', label: 'Space Ships' },
  { value: 'spies', label: 'Spies' },
  { value: 'sports', label: 'Sports' },
  { value: 'steampunk', label: 'Steampunk' },
  { value: 'superheroes', label: 'Superheroes' },
  { value: 'surfing', label: 'Surfing' },
  { value: 'swimming', label: 'Swimming' },
  { value: 'theater', label: 'Theater' },
  { value: 'time_travel', label: 'Time Travel' },
  { value: 'trains', label: 'Trains' },
  { value: 'treasure_hunts', label: 'Treasure Hunts' },
  { value: 'trucks', label: 'Trucks' },
  { value: 'underwater', label: 'Underwater' },
  { value: 'unicorns', label: 'Unicorns' },
  { value: 'video_games', label: 'Video Games' },
  { value: 'vikings', label: 'Vikings' },
  { value: 'volcanoes', label: 'Volcanoes' },
  { value: 'weather', label: 'Weather' },
  { value: 'wild_west', label: 'Wild West' },
  { value: 'wizards', label: 'Wizards' },
  { value: 'zombies', label: 'Zombies' }
]


function ChildStoryForm() {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState('')
  const [age, setAge] = useState('')
  const [interests, setInterests] = useState([{ value: 'random', label: t('interests.random') }]); 
  const [includeChild, setIncludeChild] = useState(true) // Toggle to include the child
  const [loading, setLoading] = useState(false) // State to track loading
  const [options, setOptions] = useState([]);
  const [gender, setGender] = useState('neutral'); // State for gender
  const [tempId, setTempId] = useState(null);  // Track tempId
	//   const { setIsLoading } = useWebSocket();  // This must be inside the component
  const { loadingProgress, loadingStatus, isLoading, setIsLoading, connectWebSocket } = useWebSocket(); // Use WebSocket context
  const navigate = useNavigate()

  const handleGenderSelect = (selectedGender) => {
    setGender(selectedGender);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const translatedInterestOptions = interestOptions.map(option => ({
    value: option.value,
    label: t(`interests.${option.value}`),
  }));
  // This function runs when the age is selected
  const handleAgeChange = (e) => {
    const selectedAge = e.target.value;
    setAge(selectedAge);  // Update age state
    
    if (selectedAge) {
      // Generate tempId and connect WebSocket
      const newTempId = uuidv4();
      setTempId(newTempId);
      
      // Connect WebSocket when age is selected
      connectWebSocket(null, newTempId);
	   console.log("Talking to stuff")
      console.log(`WebSocket connected with tempId: ${newTempId}`);
    }
  };
  // Create translated options whenever the language changes
  useEffect(() => {
    console.log(t('interests.random'));
    const translatedInterestOptions = [
      { value: 'random', label: t('interests.random') },
      { value: 'airplanes', label: t('interests.airplanes') },
      { value: 'aliens', label: t('interests.aliens') },
      { value: 'ambulances', label: t('interests.ambulances') },
      { value: 'animals_farm', label: t('interests.animals_farm') },
      { value: 'animals', label: t('interests.animals') },
      { value: 'animals_safari', label: t('interests.animals_safari') },
      { value: 'astronomy', label: t('interests.astronomy') },
      { value: 'baking', label: t('interests.baking') },
      { value: 'baseball', label: t('interests.baseball') },
      { value: 'basketball', label: t('interests.basketball') },
      { value: 'bees', label: t('interests.bees') },
      { value: 'biking', label: t('interests.biking') },
      { value: 'birds', label: t('interests.birds') },
      { value: 'board_games', label: t('interests.board_games') },
      { value: 'building', label: t('interests.building') },
      { value: 'butterflies', label: t('interests.butterflies') },
      { value: 'camping', label: t('interests.camping') },
      { value: 'card_games', label: t('interests.card_games') },
      { value: 'cars', label: t('interests.cars') },
      { value: 'castles', label: t('interests.castles') },
      { value: 'cats', label: t('interests.cats') },
      { value: 'chemistry', label: t('interests.chemistry') },
      { value: 'chess', label: t('interests.chess') },
      { value: 'circus', label: t('interests.circus') },
      { value: 'clay_modeling', label: t('interests.clay_modeling') },
      { value: 'coding', label: t('interests.coding') },
      { value: 'construction_vehicles', label: t('interests.construction_vehicles') },
      { value: 'cooking', label: t('interests.cooking') },
      { value: 'crafts', label: t('interests.crafts') },
      { value: 'dancing', label: t('interests.dancing') },
      { value: 'dinosaurs', label: t('interests.dinosaurs') },
      { value: 'dogs', label: t('interests.dogs') },
      { value: 'dragons', label: t('interests.dragons') },
      { value: 'drawing', label: t('interests.drawing') },
      { value: 'explorers', label: t('interests.explorers') },
      { value: 'fairies', label: t('interests.fairies') },
      { value: 'fashion', label: t('interests.fashion') },
      { value: 'firetrucks', label: t('interests.firetrucks') },
      { value: 'fishing', label: t('interests.fishing') },
      { value: 'frogs', label: t('interests.frogs') },
      { value: 'gardening', label: t('interests.gardening') },
      { value: 'geology', label: t('interests.geology') },
      { value: 'hiking', label: t('interests.hiking') },
      { value: 'history', label: t('interests.history') },
      { value: 'horses', label: t('interests.horses') },
      { value: 'insects', label: t('interests.insects') },
      { value: 'knights', label: t('interests.knights') },
      { value: 'knitting', label: t('interests.knitting') },
      { value: 'lego', label: t('interests.lego') },
      { value: 'magic', label: t('interests.magic') },
      { value: 'magic_spells', label: t('interests.magic_spells') },
      { value: 'makeup', label: t('interests.makeup') },
      { value: 'medieval', label: t('interests.medieval') },
      { value: 'mermaids', label: t('interests.mermaids') },
      { value: 'monsters', label: t('interests.monsters') },
      { value: 'motorcycles', label: t('interests.motorcycles') },
      { value: 'music', label: t('interests.music') },
      { value: 'mysteries', label: t('interests.mysteries') },
      { value: 'mythology', label: t('interests.mythology') },
      { value: 'ninja', label: t('interests.ninja') },
      { value: 'origami', label: t('interests.origami') },
      { value: 'outer_space', label: t('interests.outer_space') },
      { value: 'painting', label: t('interests.painting') },
      { value: 'pets', label: t('interests.pets') },
      { value: 'pirates', label: t('interests.pirates') },
      { value: 'pirates_treasure', label: t('interests.pirates_treasure') },
      { value: 'police_cars', label: t('interests.police_cars') },
      { value: 'princesses', label: t('interests.princesses') },
      { value: 'puppets', label: t('interests.puppets') },
      { value: 'puzzles', label: t('interests.puzzles') },
      { value: 'reptiles', label: t('interests.reptiles') },
      { value: 'robots_ai', label: t('interests.robots_ai') },
      { value: 'robots', label: t('interests.robots') },
      { value: 'samurai', label: t('interests.samurai') },
      { value: 'science_experiments', label: t('interests.science_experiments') },
      { value: 'secret_agents', label: t('interests.secret_agents') },
      { value: 'sewing', label: t('interests.sewing') },
      { value: 'singing', label: t('interests.singing') },
      { value: 'skateboarding', label: t('interests.skateboarding') },
      { value: 'soccer', label: t('interests.soccer') },
      { value: 'space', label: t('interests.space') },
      { value: 'space_ships', label: t('interests.space_ships') },
      { value: 'spies', label: t('interests.spies') },
      { value: 'sports', label: t('interests.sports') },
      { value: 'steampunk', label: t('interests.steampunk') },
      { value: 'superheroes', label: t('interests.superheroes') },
      { value: 'surfing', label: t('interests.surfing') },
      { value: 'swimming', label: t('interests.swimming') },
      { value: 'theater', label: t('interests.theater') },
      { value: 'time_travel', label: t('interests.time_travel') },
      { value: 'trains', label: t('interests.trains') },
      { value: 'treasure_hunts', label: t('interests.treasure_hunts') },
      { value: 'trucks', label: t('interests.trucks') },
      { value: 'underwater', label: t('interests.underwater') },
      { value: 'unicorns', label: t('interests.unicorns') },
      { value: 'video_games', label: t('interests.video_games') },
      { value: 'vikings', label: t('interests.vikings') },
      { value: 'volcanoes', label: t('interests.volcanoes') },
      { value: 'weather', label: t('interests.weather') },
      { value: 'wild_west', label: t('interests.wild_west') },
      { value: 'wizards', label: t('interests.wizards') },
      { value: 'zombies', label: t('interests.zombies') }
    ];

    setOptions(translatedInterestOptions);
  }, [i18n.language, t]); // Update options whenever language changes
  console.log("Temp ID generated:", tempId);


const handleSubmit = async (e) => {
  e.preventDefault();
  console.log("Form submitted");
  // This will set isLoading to true internally
  //connectWebSocket(null, tempId);

  const currentLanguage = i18n.language;
//  const tempId = uuidv4();
 // console.log("Temp ID generated:", tempId);

  // This will set isLoading to true internally
  //connectWebSocket(null, tempId);

  console.log("About to make POST request");
  try {
    console.log("POST request data:", {
      gender,
      name,
      age,
      interests: interests.map((interest) => interest.value),
      includeChild,
      currentLanguage,
      tempId
    });
        setIsLoading(true);
    const response = await axios.post('/api/generate-story', {
      gender,
      name,
      age,
      interests: interests.map((interest) => interest.value),
      includeChild,
      currentLanguage,
      tempId
    });

    console.log("POST request successful, response:", response.data);

    // Update WebSocket connection with the real slug
    //connectWebSocket(response.data.slug, null);

    // Navigate to the story page
	 console.log(`navigating to: slug: ${response.data.slug} title: ${response.data.title}`);
	   //setSlugFromState(response.data.slug);
    navigate('/story', {
      state: {
        //story: response.data.story,
        title: response.data.title,
        shortLink: response.data.shortLink,
        slug: response.data.slug,
        isNewlyGenerated: true
      }
    });
  } catch (error) {
    console.error("Error in POST request:", error);
    navigate('/oops');
  }
};

// Remove "Random" when user selects any other interest
  const handleInterestsChange = (selected) => {
    if (selected.some((interest) => interest.value !== 'random')) {
      const filtered = selected.filter((interest) => interest.value !== 'random')
      setInterests(filtered)
    } else {
      setInterests(selected)
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <h2>{loadingStatus}</h2>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${loadingProgress}%` }}></div>
            </div>
            <p>{loadingProgress}% complete</p>
          </div>
        </div>
      ) : (

      <form onSubmit={handleSubmit} className='child-form'>
        <div className='form-group'>
          <label htmlFor='gender'>{t('landing-gender')}</label>
          <div className="gender-selection" role="radiogroup" aria-labelledby="gender-selection-label">
            {['boy', 'girl', 'neutral'].map((genderOption) => (
              <div
                key={genderOption}
                className={`gender-option ${gender === genderOption ? 'selected' : ''}`}
                data-gender={genderOption}
                onClick={() => handleGenderSelect(genderOption)}
                tabIndex={0}
                role="radio"
                aria-checked={gender === genderOption}
                aria-label={t(`gender-${genderOption}`)}
                onKeyDown={(e) => { if (e.key === 'Enter') handleGenderSelect(genderOption); }}
              >
                <img 
                  src={genderOption === 'boy' ? boyIcon : genderOption === 'girl' ? girlIcon : neutralIcon} 
                  alt={t(`gender-${genderOption}`)} 
                />
              </div>
            ))}
          </div>
        </div>

          <div className='form-group'>
            <label htmlFor='name'>{t('landing-name')}</label>
            <input type='text'
             id='name'
             value={name}
             onChange={(e) => setName(e.target.value)}
             placeholder={t('landing-input-name')}
             onInvalid={(e) => e.target.setCustomValidity(t('error-item-field-missing'))}
             required
            />
          </div>

          <div className='form-group'>
            <label htmlFor='age'>{t('landing-age')}</label>
            <select 
	  	id='age' 
	  	value={age} 
	  	onChange={handleAgeChange} 
		onInvalid={(e) => e.target.setCustomValidity(t('error-item-dropdown'))} 
	  	required
	  	>
              <option value=''>{t('landing-select-age')}</option>
              <option value='infant'>{t('landing-infant')}</option>
              {[...Array(17).keys()].map((n) => (
                <option key={n + 1} value={n + 1}>
                  {n + 1}
                </option>
              ))}
            </select>
          </div>

          <div className='form-group toggle-group'>
            <label htmlFor='include-child'>{t('landing-include-child')}?</label>
            <label className='toggle-switch'>
              <input type='checkbox' checked={includeChild} onChange={() => setIncludeChild(!includeChild)} />
              <span className='slider'></span>
            </label>
          </div>

          <div className='form-group'>
            <label htmlFor='interests'>{t('landing-interests')}</label>
            <Select
              id='interests'
	      key={i18n.language}
              value={interests}
              onChange={handleInterestsChange}
              options={options}
              isMulti
              placeholder='Search and select interests'
              className='interests-dropdown'
            />
          </div>

          <button type='submit' className='submit-button'>
            {t('generate-story')} ✨🧵
          </button>
        </form>
      )}
    </div>
  );
}

export default ChildStoryForm
