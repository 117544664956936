import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHeart } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import './StoryDisplay.css' // Updated styling
import mascot from '../assets/wonderyarn-mascot.png'
import { useWebSocket } from '../contexts/WebSocketContext'; // Import WebSocket context

function StoryDisplay() {
  const location = useLocation()
  const shortLink = location.state?.shortLink || 'wonderyarn.com/s/abcd1234' // Placeholder short link
  const isNewlyGenerated = location.state?.isNewlyGenerated || false;
  const [mostPopular, setMostPopular] = useState([])
  const [relatedStories, setRelatedStories] = useState([])
  const [story, setStory] = useState('') // Story content
  const [title, setTitle] = useState('Untitled Story') // Story title
  const [slugFromState, setSlugFromState] = useState(null)
  const [imageSrc, setImageSrc] = useState('../assets/wonderyarn-mascot.png') // Start with fallback image
  //const [slug, setSlug] = useState(null);
  //const { slug } = useParams();  // Get the slug from the URL
  const [coverImage, setCoverImage] = useState(mascot)
  const [storyImages, setStoryImages] = useState([mascot, mascot, mascot]);
    //const [isLoading, setIsLoading] = useState(true);
  // Check if the story is being passed from a freshly generated story
  const generatedStory = location.state?.story
  const generatedTitle = location.state?.title
  const slugMain = location.state?.slug
  const { slug: slugFromUrl } = useParams() // Get the slug from the URL
  //const slug = slugFromState || slugFromUrl
  const { loadingProgress, loadingStatus, isLoading, setIsLoading, connectWebSocket, currentSlug } = useWebSocket(); // Use WebSocket context
	const slug = slugFromState || slugFromUrl || currentSlug;

  // State to show or hide the share modal/tooltip
  const [showShare, setShowShare] = useState(false)
  const [toastVisible, setToastVisible] = useState(false) // Toast visibility state
  //const [loadingProgress, setLoadingProgress] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [storyProgress, setStoryProgress] = useState(0);
  const [imageProgress, setImageProgress] = useState(0);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shortLink)
    setToastVisible(true) // Show toast when the link is copied
    setTimeout(() => setToastVisible(false), 3000) // Hide after 3 seconds
  }
  const navigate = useNavigate();
console.log(`generated ${generatedStory}`);
useEffect(() => {
  console.log(`Current slug in StoryDisplay: ${slug}`);
}, [slug]);


  // Handle image fallback and swapping logic
  //useEffect(() => {
   // if (slug) {
     // connectWebSocket(slug); // Connect to WebSocket using the slug
   // }
  //}, [slug]);
  useEffect(() => {
    if (loadingProgress === 100 && !isLoading && !imagesLoaded) {
	    if (generatedStory) {
      loadImages(slug, { story: generatedStory});
	    }
      setImagesLoaded(true);
    }
  }, [loadingProgress, isLoading, imagesLoaded, slug]);
/*useEffect(() => {
  if (slug && !wsConnected) {
    connectWebSocket(slug);  // Connect WebSocket if it's not connected
  }
}, [slug, wsConnected]); */
	// Add wsConnected to the dependency array to check if it's connected
	//
  useEffect(() => {
    const fetchStory = async () => {
    console.log("Fetching story. isNewlyGenerated:", isNewlyGenerated, "generatedStory:", generatedStory, "slug:", slug);

    if (isNewlyGenerated && generatedStory && generatedTitle) {
      console.log("Setting newly generated story");
      setStory(generatedStory);
      setTitle(generatedTitle);
      setSlugFromState(slugMain);
      loadImages(slugMain, { story: generatedStory });
    } else if (slug) {
      console.log("Fetching existing story");
      try {
        const response = await fetch(`/api/story/${slug}`);
        if (response.status === 404) {
          navigate('/oops');
          return;
        }
        const data = await response.json();
        setStory(Array.isArray(data.story) ? data.story : []);
        setTitle(data.title || 'Untitled Story');
        loadImages(slug, {});
      } catch (error) {
        console.error("Error fetching story:", error);
        navigate('/oops');
      }
    } else {
      console.log("No slug or generated story available");
    }
	console.log(`Slug: ${slug} slugMain: ${slugMain}`)
    // Fetch related and popular stories
    if (slug) {
      const [relatedResponse, popularResponse] = await Promise.all([
        fetch(`/api/stories/related/${slugMain}`),
        fetch(`/api/stories/popular`)
      ]);
      const relatedData = await relatedResponse.json();
      const popularData = await popularResponse.json();
      setRelatedStories(relatedData);
      setMostPopular(popularData);
    }

    document.title = `${title} | WonderYarn - Custom Stories for Kids`;
  };

  fetchStory();
}, [slug, isNewlyGenerated, generatedStory, generatedTitle, slugMain, navigate]);

/*


	          const slugToUse = location.state?.slug || currentSlug;
      if (slug && !isNewlyGenerated) {
	      var response
	      var data
         response = await fetch(`/api/story/${slug}`)
	if (response.status === 404) {
		navigate('/oops');
	}
        data = await response.json()
   //     setStory(data.story || '')
	     setStory(Array.isArray(data.story) ? data.story : []);
        setTitle(data.title || 'Untitled Story')
	      console.log(`SLUG: ${slug} slugtouse: ${slugToUse}`)
      const metaDescription = document.querySelector("meta[name='description']");
      const descriptionText = `Read this magical story, '${data.title}', on WonderYarn! ${data.story.map(section => section.section_text).join(' ').substring(0, 160)}`;
      
      if (metaDescription) {
        metaDescription.setAttribute('content', descriptionText);
      } else {
        const descriptionTag = document.createElement('meta');
        descriptionTag.name = 'description';
        descriptionTag.content = descriptionText;
        document.head.appendChild(descriptionTag);
      }
        if (isNewlyGenerated && slug) {
	        console.log('Story is newly generated. Connecting WebSocket...');

        	//const closeWebSocket = connectWebSocket(slugToUse);
     	   	//return () => {
        	 // closeWebSocket();
        	//};
        } else {
		loadImages(slug);
	}
        // Load cover image
        setCoverImage(`/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}/cover.png`)
        
        // Load story images
        //setStoryImages([
        //  `/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}/story_1.png`,
        //  `/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}/story_2.png`,
        //  `/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}/story_3.png`
       // ])

        // Fetch the real image after 15 seconds
        setTimeout(() => {
          setImageSrc(`/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}_small.png`)
        }, 21000); // Retry after 15 seconds
      } else if (generatedStory && generatedTitle) {
	      console.log(`generated ${generatedStory}`);
	      console.log("here generated");
        setStory(generatedStory);
        setTitle(generatedTitle);
        setSlugFromState(slugMain);
	//connectWebSocket(slugMain);
      } else {
	      console.log("Else why am i here?")
	}

      // Fetch related stories
      const relatedResponse = await fetch(`/api/stories/related/${slugMain}`)
      const relatedData = await relatedResponse.json()
      setRelatedStories(relatedData)

      // Fetch most popular stories
      const popularResponse = await fetch(`/api/stories/popular`)
      const popularData = await popularResponse.json()
      setMostPopular(popularData)

      if (title) {
        document.title = `${title} - WonderYarn` // Update page title dynamically
      } else {
        document.title = 'WonderYarn - Personalized Bedtime Stories' // Fallback title
      }
    } 
    fetchStory()
	  document.title = `${title} | WonderYarn - Custom Stories for Kids`;
  }, [slug, story, title, slugMain, isNewlyGenerated, currentSlug])
*/
  const loadImages = (slug, storyData) => {
    setCoverImage(`/images/story/${slug.charAt(0)}/${slug.substring(0, 2)}/${slug}/cover.png`);
  // Set story images
  if (storyData.story && Array.isArray(storyData.story)) {
    const images = storyData.story
      .filter(section => section.image_path)
      .map(section => section.image_path);
    setStoryImages(images);
  } else {
    setStoryImages([]);
  }
  };

  const handleImageError = (index) => {
    if (index === -1) {
      setCoverImage(mascot)
    } else {
      setStoryImages(prevImages => prevImages.map((img, i) => i === index ? mascot : img))
    }
  }

  //const paragraphs = story.split(/(?<=[.?!])\s+/)
console.log(`SLUGGER WINDOW ${slug}`);
 window.history.pushState(null, '', `/story/${slug}`);
  const renderStoryContent = () => {
  if (!story) {
    return <p>No story content available</p>;
  }

  if (typeof story === 'string') {
    return <p>{story}</p>;
  }

  if (!Array.isArray(story)) {
    return <p>Invalid story format</p>;
  }

  const content = [];
  let imageIndex = 0;

  story.forEach((section, index) => {
    content.push(<p key={index}>{section.section_text}</p>); // Add section text

    if (section.section_text.includes('<img_placeholder>')) {
      content.push(
        <div key={`image-${imageIndex}`} className="story-image-container">
          <img
            src={section.image_path}
            onError={() => handleImageError(imageIndex)}
            alt={`Story illustration ${imageIndex + 1}`}
            className="story-image"
          />
        </div>
      );
      imageIndex++;
    }
  });

    // If there are remaining images, distribute them evenly
    while (imageIndex < storyImages.length) {
      const position = Math.floor(content.length / (storyImages.length - imageIndex + 1)) * (imageIndex + 1)
      content.splice(position, 0, 
        <div key={`image-${imageIndex}`} className="story-image-container">
          <img 
            src={storyImages[imageIndex]} 
            onError={() => handleImageError(imageIndex)}
            alt={`Story illustration ${imageIndex + 1}`}
            className="story-image"
          />
        </div>
      )
      imageIndex++
    }

    return content
  }

	{/*	  if (isLoading) {
    return (
      <div className="loading-overlay">
        <div className="loading-content">
          <h2>Loading your magical story...</h2>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${loadingProgress}%` }}></div>
          </div>
          <p>{loadingProgress}% complete</p>
        </div>
      </div>
    );
  }*/}


  return (
	      <div>
      {isNewlyGenerated && isLoading ? (
        <div className="loading-overlay">
          <div className="loading-content">
            <h2>{loadingStatus}</h2>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${loadingProgress}%` }}></div>
            </div>
            <p>{loadingProgress}% complete</p>
          </div>
        </div>
      ) : (
        <div className='story-display-container'>
          <div className='stars'></div> {/* Stars background */}
          <div className='story-image'></div>
          <div className='story-box'>
            <h2>{title}</h2>
            <div className='cover-image-container'>
              <img
                src={coverImage}
                onError={() => handleImageError(-1)}
                alt={title}
                className="cover-image"
              />
            </div>
            <div className='story-content'>
              {story ? renderStoryContent() : <p>Loading story...</p>}
            </div>
            {/* Share button inside the story box */}
            <div className='share-container'>
              <button className='share-button' onClick={() => setShowShare(!showShare)}>
                Share Story 🌟
              </button>

              {/* Share modal or tooltip */}
              {showShare && (
                <div className='share-tooltip'>
                  <p>Share this story:</p>
                  <input type='text' value={shortLink} readOnly onClick={copyToClipboard} />
                  <button className='copy-link-button' onClick={copyToClipboard}>
                    Copy Link
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isNewlyGenerated && (
        <div className="websocket-status">
          WebSocket: {wsConnected ? 'Connected' : 'Disconnected'}
        </div>
      )}
      {toastVisible && <div className='toast'>Link copied to clipboard!</div>}
      <div className='ad-section'>
        <p>Advertisement</p>
      </div>
      <div className='recommendations-section'>
        {/* "More Like This" - Hide if no results */}
        {relatedStories.length > 0 && (
          <div className='card-section'>
            <h2>More Like This</h2>
            <div className='recommendation-cards'>
              {relatedStories.slice(0, 4).map((story, index) => (
                <div className='story-card' key={index}>
                  {/* <p><a href={`/story/${story.slug}`}>{story.title}</a></p> */}
                  <p>
                    <a href={`/story/${story.slug}`}>
                      <img
                        width='150px'
                        height='150px'
                        src={`/images/story/${story.slug.charAt(0)}/${story.slug.substring(0, 2)}/${story.slug}/cover_small.png`}
                      />
                    </a>
                  </p>
                  <span>
                    <FontAwesomeIcon icon={faStar} style={{ color: '#fbd25d' }} className='star-emoji' /> {/* Yellow star */} {story.title}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* "Most Popular" */}
        <div className='card-section'>
          <h2>Most Popular</h2>
          <div className='recommendation-cards'>
            {mostPopular.slice(0, 4).map((story, index) => (
              <div className='story-card' key={index}>
                <p>
                  <a href={`/story/${story.slug}`}>
                    <img
                      width='150px'
                      height='150px'
                      src={`/images/story/${story.slug.charAt(0)}/${story.slug.substring(0, 2)}/${story.slug}/cover_small.png`}
                    />
                  </a>
                </p>
                <span>
                  <FontAwesomeIcon icon={faHeart} style={{ color: '#ff69b4' }} className='heart-emoji' /> {/* Pink heart */} {story.title}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoryDisplay;
